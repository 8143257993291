import { ref, reactive, computed, onMounted } from "vue";
import * as API from "@/API/checking/spotChecking";
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import useThousands from "@/utils/payment/useThousands";
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
import useFetch from "@/hooks/useFetch";
import { BuItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
import { getSubscribedBu } from "@/API/approve";
import { message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { createVNode } from 'vue';
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useOverflowText from "@/utils/payment/useOverflowText";

type SearchParams = {
    entity?: string[];
    bu?: string[];
    programCode: string;
    programName: string;
}

type ShowParams = {
    payoutRoundId: string;
    payoutRound: string;
    payoutRoundStatus: string;
    wTotal: string;
    woTotal: string;
}

export interface TableData {
    programIds: string;
    entity: string;
    bu: string;
    programCode: string;
    programName: string;
    dealerNo: string;
    vinNo: string;
    amountWVat: string;
    amountWOVat: string;
}

const useVehicleNameSet = () => {
    const { commit } = useStore()
    const router = useRouter()

    const showParams = reactive<ShowParams>({
        payoutRoundId: '',
        payoutRound: '',
        payoutRoundStatus: '',
        wTotal: '',
        woTotal: ''
    })
    const searchParams = reactive<SearchParams>({
        entity: undefined,
        bu: undefined,
        programCode: '',
        programName: '',
    })
    const total = ref<number>(0)
    const pageNumber = ref<number>(0)
    const pageSize = ref<number>(10)
    const columnsData = computed(() => {
        return [
            { title: "Entity", dataIndex: "entity",width:80},
            { title: "BU", dataIndex: "bu",width:80},
            { title: "Program Code", dataIndex: "programCode",width:150},
            { title: "Program Name", dataIndex: "programName",slots: { customRender: 'programName' ,},width:120, },
            { title: "Dealer No.", dataIndex: "dealerNo", customRender: useThousands(), align: "right",width:80},
            { title: "VIN No.", dataIndex: "vinNo", customRender: useThousands(), align: "right",width:80},
            { title: "Amount w/o VAT", dataIndex: "amountWOVat", customRender: useThousandsAndTwoDecimal(), width: 130, align: "right"},
            { title: "Amount w VAT", dataIndex: "amountWVat", customRender: useThousandsAndTwoDecimal(), width: 130, align: "right"},
            { title: "Operation", dataIndex: "operation",slots: { customRender: 'operation' }, align: "center",width:120}
        ]
    })
    const tableData = ref<TableData[]>()
    const addAllClamsLoading = ref(false)

    const getTableData = () => {
        tableData.value = []
        showParams.wTotal = '0.00'
        showParams.woTotal = '0.00'
        const params = {
            bus: searchParams.bu,
            entities: searchParams.entity,
            payoutRoundId: showParams.payoutRoundId,
            programCode: searchParams.programCode,
            programName: searchParams.programName
        }
        API.getProgramList(params).then((data: any) => {
            if (data) {
                tableData.value = data.spotCheckProgramDTOS
                // total.value = data.totalElements
                showParams.wTotal = toThousandsAndTwoDecimal(data.totalAmountWVat)
                showParams.woTotal = toThousandsAndTwoDecimal(data.totalAmountWOVat)
            }
        })
    }
    const getShowParams = () => {
        API.getLastPayoutRound().then((data: any) => {
            showParams.payoutRoundId = data.payoutRoundId
            showParams.payoutRound = data.payoutRound
            showParams.payoutRoundStatus = data.status
            getTableData()
        })
    }
    const doSearch = () => {
        console.log('search按钮')
        pageNumber.value = 1
        getTableData()
    }
    const doReset = () => {
        Object.assign(searchParams, {
            entity: undefined,
            bu: undefined,
            programCode: '',
            programName: '',
        })
        tableData.value = []
        getTableData()
    }
    const pageChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    };
    const sizeChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    }
    const programNameClick = (record: any) => {
        const info = {
            payoutRoundId: showParams.payoutRoundId,
            payoutRound: showParams.payoutRound,
            programName: record.programName,
            programIds: record.programIds,
            payoutRoundStatus: showParams.payoutRoundStatus,
            amountWVat: record.amountWVat,
            amountWOVat: record.amountWOVat
        }
        commit('spotChecking/updatePayoutCurRow', info)
        router.push({ path: '/spotCheckingControlling/payoutDetail' })
    }
    const deleteClick = (record: TableData) => {
        Modal.confirm({
            title: 'Remove from Current Payout Round',
            icon: createVNode(ExclamationCircleOutlined),
            okText: 'Delete',
            okType: 'primary',
            cancelText: 'Cancle',
            onOk() {
                API.payoutPreparationDelete({
                    payoutRoundId: showParams.payoutRoundId,
                    programIds: record.programIds
                }).then(() => {
                   message.success('Successfully Delete!')
                   getTableData()
                })
            }
        })
    }
    const addAllClaims = () => {
        addAllClamsLoading.value = true
        API.addAllClaim(showParams.payoutRoundId).then(() => {
            message.success('Successfully Add all claims!')
            doReset()
        }).finally(() => {
          addAllClamsLoading.value = false
        })
    }
    const submitClick = () => {
        Modal.confirm({
            title: 'Submit Payout Round for L4 Confirm',
            icon: createVNode(ExclamationCircleOutlined),
            okText: 'Submit',
            okType: 'primary',
            cancelText: 'Cancle',
            onOk() {
                API.payoutPreparationSubmit(showParams.payoutRoundId).then((data: any) => {
                    showParams.payoutRoundId = data.payoutRoundId
                    showParams.payoutRound = data.payoutRound
                    showParams.payoutRoundStatus = data.status
                    message.success('Successfully Submit!')
                    getTableData()
                })
            }
        })
    }
    const roundVisible = ref<boolean>(false)
    const roundClick = () => {
      roundVisible.value = true
    }
    const closeRound = (param: { uploadVisible: boolean }) => {
      roundVisible.value = param.uploadVisible
    }
    const confirmRound = ([row]: any[]) => {
      showParams.payoutRoundId = row.payoutRoundId
      showParams.payoutRound = row.payoutRound
      showParams.payoutRoundStatus = row.status
      closeRound({ uploadVisible: false })
      getTableData()
    }
    onMounted(() => {
        getShowParams()
        doSearch()
    })
    return {
        searchParams,
        showParams,
        columnsData,
        tableData,
        addAllClamsLoading,
        doSearch,
        doReset,
        pageChange,
        sizeChange,
        pageSize,
        pageNumber,
        total,
        programNameClick,
        deleteClick,
        addAllClaims,
        submitClick,
        roundVisible,
        roundClick,
        closeRound,
        confirmRound
    }
}
export default useVehicleNameSet