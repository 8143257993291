
import { defineComponent, ref } from "vue";
import usePaymentPreparation from "@/hooks/checking/spotChecking/usePaymentPreparation";
import { ColumnProps } from "ant-design-vue/es/table/interface";
type Key = ColumnProps["key"];
const tableWidth = window.innerWidth;
const tableHeight = window.innerHeight - 482;
import TableWrapper from "@/components/TableWrapper.vue";
import { useStore } from "vuex";
import PayoutRound from "./payoutRound.vue";
import BuSelector from "@/views/Payment/components/BuSelector.vue";
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
export default defineComponent({
  name: "DealerVendorCodeSet",
  components: {
    // Pagination,
    TableWrapper,
    PayoutRound,
    BuSelector,
    EntitySelector,
  },
  props: {},
  setup() {
    const store = useStore();
    const {
      searchParams,
      showParams,
      doReset,
      columnsData,
      tableData,
      addAllClamsLoading,
      doSearch,
      pageChange,
      sizeChange,
      pageSize,
      pageNumber,
      total,
      programNameClick,
      deleteClick,
      addAllClaims,
      submitClick,
      roundVisible,
      roundClick,
      closeRound,
      confirmRound,
    } = usePaymentPreparation();
    const handlePageChange = (page: number, pageSize: number) => {
      pageChange(page, pageSize);
    };
    const handleSizeChange = (page: number, pageSize: number) => {
      sizeChange(page, pageSize);
    };

    return {
      store,
      searchParams,
      showParams,
      doSearch,
      doReset,
      tableWidth,
      tableHeight,
      columnsData,
      tableData,
      pageSize,
      pageNumber,
      total,
      addAllClamsLoading,
      handlePageChange,
      handleSizeChange,
      programNameClick,
      deleteClick,
      addAllClaims,
      submitClick,
      roundVisible,
      roundClick,
      closeRound,
      confirmRound,
    };
  },
});
