
  import { defineComponent, ref, computed, reactive, watch } from 'vue'
  import * as API from "@/API/checking/spotChecking";
  import { ColumnProps } from 'ant-design-vue/es/table/interface';
  import YearPicker from '@/views/Payment/components/YearPicker.vue'
  import moment from "moment";
  import { Moment } from 'moment';
  import { message } from 'ant-design-vue';
  
  type Key = ColumnProps['key'];

  type SearchParams = {
    year: Moment | null;
    month: Moment | null;
  }

  interface TableData {
    payoutRound: string;
    status: string;
    statusUpdateDate: string;
  }

  const roundColumns = [
    { title: "Payout round", dataIndex: "payoutRound" },
    { title: "Status", dataIndex: "status", width: 200 },
    { title: "Update Date", dataIndex: "statusUpdateDate", width: 110, slots: { customRender: "statusUpdateDate"} }
  ]
  
  export default defineComponent({
    emits: ["close-upload-visible", "confirm-round"],
    props: {
      isUploadVisible: {
        type: Boolean,
        required: true,
        default: false
      },
      payoutRound: {
        type: String,
        required: true,
        default: ''
      }
    },
    components: {
      YearPicker
    },
    setup(props, context) {
      const searchParams = reactive<SearchParams>({
          year: null,
          month: null
      })
      // let payoutRound: string
      const roundData = ref<null | unknown>(null)
      const rowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: TableData[]; onChange: Function;columnWidth: string; type: string}>({
        onChange: (selectedRowKeys: Key[], selectedRows: TableData[]) => {
          rowSelection.selectedRowKeys = selectedRowKeys;
          rowSelection.selectedRowData = selectedRows;
        },
        selectedRowKeys: [],
        selectedRowData: [],
        columnWidth: '20px',
        type: 'radio'
      })

      const getRoundList = () => {
        rowSelection.selectedRowKeys = []
        rowSelection.selectedRowData = []
        const params = {
          month: searchParams.month ? moment(searchParams.month).format('M') : '',
          year: searchParams.year ? moment(searchParams.year).format('YYYY') : ''
        }
        API.getPayoutRoundList(params).then((data: any) => {
            roundData.value = data
            const index = data.findIndex((item: TableData) => props.payoutRound === item.payoutRound)
            if (index !== -1) {
              rowSelection.selectedRowKeys = [ props.payoutRound ]
              rowSelection.selectedRowData = [ data[index] ]
            }
        })
      }
      watch(searchParams, () => {
        getRoundList()
      })
      API.getPayoutPreparationYear().then((data: any) => {
        searchParams.year = moment(data.toString())
      })
      // 获取标题内容
      const title = computed(() => `Payout Round`)
      // // 设置抽屉显示状态
      // const modal = (props as any).isUploadVisible;
      const close = () => {
        context.emit('close-upload-visible', { isUploadVisible: false })
      }
      const onConfirm = () => {
        context.emit('confirm-round', rowSelection.selectedRowData)
      }

      const modalVisible = ref(false)
      const modalLoading = ref(false)
      const modalData = ref({
        prefix: '',
        remark: ''
      })
      const modalShow = () => {
        const params = {
          month: moment(searchParams.month).format('M'),
          year: moment(searchParams.year).format('YYYY')
        }
        API.getPayoutRoundPrefix(params).then((data: any) => {
          modalData.value.prefix = data
        })
        modalVisible.value = true
      }
      const closeModal = () => {
        modalData.value.prefix = ''
        modalData.value.remark = ''
        modalVisible.value = false
      }
      const savePayoutRound = () => {
        modalLoading.value = true
        const params = {
          month: searchParams.month ? moment(searchParams.month).format('M') : '',
          year: searchParams.year ? moment(searchParams.year).format('YYYY') : '',
          payoutRound: modalData.value.prefix + (modalData.value.remark ? '_' + modalData.value.remark : ''),
          remark: modalData.value.remark,
        }
        API.savePayoutRound(params).then((data: any) => {
          modalLoading.value = false
          message.success('Successfully Save!')
          closeModal()
          getRoundList()
        }).catch(() => {
          modalLoading.value = false
        })
      }

      return {
        searchParams,
        roundData,
        title,
        // modal,
        close,
        onConfirm,
        roundColumns,
        rowSelection,
        modalVisible,
        modalLoading,
        modalShow,
        modalData,
        closeModal,
        savePayoutRound
      }
    }
  })
